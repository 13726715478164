export enum Endpoint {
	RECOMMENDATIONS = 'Recommendations',
	RECOMMENDATION = 'Recommendation',
	RECOMMENDATIONPRODUCTS = 'RecommendationProducts',
	RECOMMENDATIONPRODUCT = 'RecommendationProduct',
	TIMESOFAPLLICATION = 'TimesOfApplication',
	APPLICATIONINSTRUCTIONS = 'ApplicationInstructions',
	CLIENTS = 'Clients',
	CONTACTS = 'Contacts',
	FACONTACTS = 'FaContacts',
	FARMS = 'Farms',
	FARMBLOCKS = 'FarmBlocks',
	FIELDASSESSMENTS = 'FieldAssessments',
	LIBRARYCATEGORIES = 'LibraryCategories',
	USERPROFILE = 'UserProfile',
	RECIPE = 'Recipe',
	RECIPES = 'Recipes',
	RECIPEPRODUCT = 'RecipeProduct',
	RECIPEPRODUCTS = 'RecipesProducts',
	OBSERVATIONS = 'Observations',
}

export const TAG_TYPES: readonly string[] = Object.values(Endpoint);

export enum TagId {
	PARTIAL_LIST = 'PARTIAL-LIST',
}
